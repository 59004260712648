import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(ContainerBase)`bg-[#fce4f0] py-6 text-textBlack -mx-8 px-8 xs:px-16`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl text-left md:text-center lg:text-center`;
const Subheading = tw(SubheadingBase)`text-gray-100`;
const Description = tw(SectionDescription)`font-medium text-secondary-400 text-base leading-loose text-left md:text-center lg:text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex   flex-row items-center justify-center sm:justify-between flex-wrap max-w-screen-md mx-auto`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
const StatKey = tw.div`text-xl font-medium`
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`
const Lang1 = tw.span`font-bold text-black leading-tight text-[#231f66]`;
const Panth1 = tw.span`font-bold text-black leading-tight text-[#2fb3c0]`;
export default ({
  subheading = "",
  heading = "Fastest Growing CA Foundation Learning Platform",
  description = "Academic Endorsement by - CA Jiting Tyagi, CA Harsh Gupta & CA Vijender Aggarwal",
  stats = [
    {
      key: "Notes Pages",
      value: "1000+",
    },
    {
      key: "Engaged Users",
      value: "20K+",
    },
    {
      key: "Solved Questions",
      value: "10K+",
    },
  ]
}) => {
  return (
    <Container>
     
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer>
     
    </Container>
  );
};
