import React,{useState,useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainBanner from "components/hero/YoungHeroContent.js";
import FastestGrowing from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import fitdeutsch from "images/ca-foundation_accounts_youngminds.png";
import gols from "images/goals.png";
import coursedetail from "images/course detail.png";
import { components } from "ComponentRenderer.js";
import { Helmet } from "react-helmet";
import MainFeature from "components/features/TwoColWithButton.js";
import upcoming from "images/upcoming batch.png";

const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-brand-500`;
const HighlightedText = tw.span`text-brand-500 `;
const Title = tw.h2`font-bold mt-4 text-4xl md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight`;
const Gtext = tw.span`text-brand-500 font-bold`;
const Ltext = tw.span`text-brand-700 font-bold`;
const BoldTag = styled.span`
  ${tw`text-textBlack font-bold`}
`;
export default ({
  title = "Who Should Do It ??",
  highlightedtext = "You - ",
  heading = "Ideal If ",
  CheckIcon = Checkicon,
  innerPages = components.innerPages,
}) => {

  return (
    <>
      <Helmet
        title="Young Minds - Simplifying German Language Learning"
        meta={[
          {
            name: "description",
            content:
              "Streamline and Simplify German Language Learning for Global Career Goals",
          },
          {
            property: `og:title`,
            content: "Young Minds - Simplifying German Language Learning",
          },
          {
            property: `og:description`,
            content: "Streamline and Simplify German Language Learning for Global Career Goals",
          },
          {
            property: `og:image`,
            content: "https://aurumfiles.b-cdn.net/fit_in_deutsch_1.jpg",
          },
          {
            property: `og:image:alt`,
            content: "YoungMinds German A1 Certificate Exam Program",
          },
          {
            property: `og:image:width`,
            content: "500",
          },
          {
            property: `og:image:height`,
            content: "500",
          },
          {
            property: `og:url`,
            content:"https://youngminds.pro/fit-in-deutsch-1",
          },
          {
            property: `og:type`,
            content: "website",
          },
        ]}
      />
      

      <AnimationRevealPage>
        <MainBanner roundedHeaderButton={true} 
        mainTitle="German Course for A1" 
        subTitle={"<p><p>Obtaining a <b>German A1 Certificate</b> holds significant importance for those aspiring to <b>Study in Germany</b> or is a starting point for those looking for a <b>Career with German Language Skills</b>. This certification signifies the foundational language skills necessary to navigate academic and daily life in a German-speaking environment.</p><p>&nbsp;</p><p>It serves as a vital entry point for international students, facilitating their integration into German universities and enhancing their overall educational experience.</p></p>"}
        buttonText="Let's Talk"
        buttonUrl="https://wa.me/918826622806/?text=Hi, I would like to know more About German A1 Program"
        imageSrc={fitdeutsch}
        showClient= {false}
        
        />
        <Faqs
         imageSrc={upcoming}

        roundedHeaderButton={true}
        heading={<Title>Upcoming Batches - <Gtext>German A1</Gtext></Title>}
        description={<batchHead>We conduct <BoldTag>Online Live Interactive</BoldTag> Sessions and <b>Offline / Face 2 Face</b> Classes at Green Park, Delhi. <br /></batchHead>}
        textOnLeft={false}
        buttonUrl="https://wa.me/918826622806/?text=Hi, I want to Join German A1 Classroom Program..."
        buttonText="Lets Connect"
        faqs={[{
      question: <batchHead><Ltext>Weekday Batch</Ltext> - 15th Dec 2023, Friday, 7.00 PM</batchHead>,
      answer:
        "<p><b>Course Duration</b> : 4-6 Months</p><p><b>Weekly Classes</b> : 3 to 4 Days, 1-3 Hour Each</p><p><b>Study Material</b> : Books Included</p><p><b>Backup </Gtext>Classes</b> : Recordings of Live Classes</p><p><b>Access Type</b> : Mobile App / Laptop</p><p><b>Batch Types : </b>Weekend, Weekdays, Fast Track and Regular Batches</p>"
        },
        {
      question: <batchHead><Ltext>Weekend Batch</Ltext> - 15th Dec 2023, Friday, 7.00 PM</batchHead>,
      answer:
        "<p><b>Course Duration</b> : 4-6 Months</p><p><b>Weekly Classes</b> : 3 to 4 Days, 1-3 Hour Each</p><p><b>Study Material</b> : Books Included</p><p><b>Backup </Gtext>Classes</b> : Recordings of Live Classes</p><p><b>Access Type</b> : Mobile App / Laptop</p><p><b>Batch Types : </b>Weekend, Weekdays, Fast Track and Regular Batches</p>"
        },
        {
      question: <batchHead><Ltext>Weekday Batch</Ltext> - 28th Sep 2023, Thursday, 7.00 PM</batchHead>,
      answer:
        "<p><b>Course Duration</b> : 4-6 Months</p><p><b>Weekly Classes</b> : 3 to 4 Days, 1-3 Hour Each</p><p><b>Study Material</b> : Books Included</p><p><b>Backup </Gtext>Classes</b> : Recordings of Live Classes</p><p><b>Access Type</b> : Mobile App / Laptop</p><p><b>Batch Types : </b>Weekend, Weekdays, Fast Track and Regular Batches</p>"
        }]}

      />
      <FeatureWithSteps
          subheading={<SubheadingSteps>{title}</SubheadingSteps>}
          heading={
            <>
              {heading} <HighlightedText>{highlightedtext}</HighlightedText>
            </>
          }
          textOnLeft={true}
          imageSrc={gols}
          steps={[
            {
              mainIcon: "",
              heading: "Are a Study Abroad Aspirant",
              description:
                "Germany is the Top Destination for Graduation and Post Graduation.",
            },

            {
              mainIcon: "",
              heading: "Want to Learn Basics of German",
              description:
                "Take the First Step to German Proficiency, Open the World of Opportunities.",
            },

            {
              mainIcon: "",
              heading: "Wish to Get Global Certification",
              description:
                "Get an Official and Internationally recognized Goethe Certificate.",
            },
          ]}
        />
        <TrackRecord
          textOnLeft={false}
          heading={
            <>
              General Info About{" "}
              <HighlightedText>German A1 Exam</HighlightedText>
            </>
          }
          description={
            <>
              <p>
                {" "}
               <p>The Goethe-Zertifikat A1, commonly referred to as the <b>German A1 Certificate Exam</b>, is designed for adult learners, minimum age is 16 years.</p><p>&nbsp;</p><p>It serves as proof that candidates have attained fundamental language proficiency, aligning with the initial level (A1) within the Common European Framework of Reference for Languages (CEFR), which consists of six proficiency levels.</p>
              </p>
              <br />
              <BoldTag>Passing the Exam Demonstrates ...</BoldTag>
              <p>
                1. You speak in a straightforward manner when conversing with someone who speaks slowly and articulates their words clearly.,
                <br />
                2. you comprehend and employ familiar, everyday, and frequently used expressions as well as basic sentences. These may include sharing information about yourself and your family or discussing topics like shopping, work, and your immediate surroundings.,
                <br />
                3. you introduce both yourself and others, and inquire about individuals' backgrounds, such as their place of residence, acquaintances, and possessions.
              </p>
              <br />
              <BoldTag>Exam Pattern of German A1 Exam</BoldTag>
              <p>
                The Goethe-Zertifikat A1 Exam for Adults comprises of 4
                sections -{" "}
                <HighlightedText>
                  Reading, Listening, Writing and Speaking
                </HighlightedText>{" "}
                sections. The speaking section of the exam is completed in a
                group. The exam is{" "}
                <BoldTag>
                  administered and evaluated in the same way all over the world
                </BoldTag>
                .
              </p>
            </>
          }
          imageSrc={coursedetail}
          subheading="First Step for Young Learners Towards German Proficiency"
          statistics = {[{
          key: "Reading",
          value: "25 Mins"
        },
        {
          key: "Writing",
          value: "20 Mins"
        },
        {
          key: "Listening",
          value: "20 Mins"
        },
        {
          key: "Speaking",
          value: "15 Mins"

        }]}
        />
        

        <Faqs
          textOnLeft={false}
          buttonUrl="https://wa.me/918826622806/?text=Hi, I need more info on German A1 Exam ..."
          buttonText="Lets Connect"
          description="Here are some frequently asked questions about Goethe-Zertifikat A1: German A1 Exam for Adults, feel free to reach out in case of we missed out something."
          faqs={[
            {
              question:
                "What are the Eligibility / Requirements of this Exam ??",
              answer:
                "<p>The German A1 Exam is an exam for Adults with Minimum of 16 years of age.</p><p>The Goethe-Institut exams are available to all interested parties and can be taken regardless of age and nationality.</p><p>To sit the Goethe Exam for A1, candidates must have German language skills corresponding to the first level of competence (A1) of the Common European Framework of Reference for Languages (CFR).</p>",
            },
            {
              question: "How is Speaking Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to introduce yourself to the group before taking part in a question-and-answer session on everyday matters and asking someone in the group forsomething.</p><p><b>Duration of the Speaking Ability Test is 15 Mins</b></p>",
            },
            {
              question: "How is Listening Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to listen to short everyday conversations, telephone messages or public announcements over a loudspeaker and complete exercises on what you have heard.</p><p><b>Duration of the Listening Ability Test is 20 Mins</b></p>",
            },
            {
              question: "How is Reading Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to read texts such as brief notes, classified advertisements, signposts and posters and complete exercises on these texts.</p><p><b>Duration of the Listening Ability Test is 25 Mins</b></p>",
            },
            {
              question: "How is Writing Ability Tested in Exam ??",
              answer:
                "<p>You will be asked to fill in simple forms and write a short text about yourself on an everyday topic.</p><p><b>Duration of the Listening Ability Test is 20 Mins</b></p>",
            },
          ]}
        />
        <Testimonials
          subheading="Learn German from the Best Institute in India"
          heading="Our Students Love Us."
          description={
            <>
              <BoldTag>The Reason why Learners Love YoungMinds,</BoldTag> is
              that we work with you from start to finish. We pride ourselves in
              being fully engaged with your german learning journey, to help you
              open global opportunities for education and career growth.
            </>
          }
          imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/testimonials2.jpg"
          textOnLeft={true}
          testimonials={[
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/st_testimonial_img.jpeg",
              heading:
                "Didn’t Expected Language Learning can be So Interesting & Engaging",
              quote:
                "My child always struggled to stay engaged while learning German, but my experience with YoungMinds and LP has been a game-changer. They used innovative methods to make learning German interesting and engaging. The lessons were interactive and Technology Assisted. I didn't expect language learning to be this much fun! I would highly recommend YoungMinds to anyone who wants to make learn German while having a great time.",
              customerName: "Showick Thorpe",
              customerTitle: "Founder, V&S Thorpes Education Inc.",
            },
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/ac_testimonial_img.jpeg",
              heading: "Love the Teaching Experience and Technology Tools",
              quote:
                "I had the pleasure of learning German with a facutly team who combined their extensive teaching experience with the latest technology tools. The classes were interactive and engaging, and the Institute was able to identify the strengths and weaknesses to tailor the lessons accordingly. The use of online resources and platforms made learning more accessible and convenient. I highly recommend YoungMinds and Language Pantheon to improve German language skills in a easy and effective way.",
              customerName: "Amit Chawla",
              customerTitle: "FCA, Content Creator at EY",
            },
            {
              stars: 5,
              profileImageSrc:
                "https://aurumfiles.b-cdn.net/ag_testimonial_img.jpeg",
              heading: "Individualized Attention & Effective Learning",
              quote:
                "We didn’t really know what sort of help we most needed for our daughter in class 8th. But we knew we needed help for German. Learning with Young Minds & Language Pantheon has made a real difference. Our kid now has more energy and confidence in German Language. We are moving forwards in a positive way and are confident that it will surely pay off in her career making years.",
              customerName: "Achal Gupta",
              customerTitle: "CEO, Kiran Prakashan",
            },
          ]}
        />
        <FastestGrowing />
        <PreFooter fromPage = "German A1" formheading = "Learning a Foreign Language in School Life Amplies Your Career Prospects and Personality" formsubheading = "Get More Details About the Program." heightlightText="Lets Start Learning"/>
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
