import React,{useState,useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainBanner from "components/hero/YoungHeroContent.js";
import FastestGrowing from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import OurExpertise from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonials from "components/testimonials/TwoColumnWithImageAndRating.js";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Faqs from "components/faqs/SimpleWithSideImage.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as Checkicon } from "../images/check.svg";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import caseStudyLogoImage from "images/clientLogos/languagePantheon.png";
import fitdeutsch from "images/ca-foundation_accounts_youngminds.png";
import gols from "images/goals.png";
import VideoContentImage from "images/youngminds_language.png";
import coursedetail from "images/course detail.png";
import { components } from "ComponentRenderer.js";
import { Helmet } from "react-helmet";

const Heading = tw.h4`text-black font-bold text-left xs:text-xl sm:text-2xl text-left md:text-left leading-tight`;
const SubheadingSteps = tw.span`uppercase tracking-widest font-bold text-brand-500`;
const HighlightedText = tw.span`text-brand-500 `;
const Title = tw.h2`font-bold text-4xl md:text-4xl lg:text-4xl xl:text-5xl text-black leading-tight text-left px-6 md:px-0 lg:px-0`;
const Gtext = tw.span`text-brand-500 font-bold`;
const Ltext = tw.span`text-brand-700 font-bold`;
const BoldTag = styled.span`
  ${tw`text-textBlack font-bold`}
`;
export default ({
  title = "Who Should Do It ??",
  highlightedtext = "You - ",
  heading = "Ideal If ",
  CheckIcon = Checkicon,
  innerPages = components.innerPages,
}) => {

  return (
    <>
      <Helmet
        title="Young Minds - Simplifying CA Foundation Accounts Preparation"
        meta={[
          {
            name: "description",
            content:
              "Streamline and Simplify CA Foundation Accounts Prep with Notes, Shortcuts, Videos, Tests and Discussions",
          },
          {
            property: `og:title`,
            content: "Young Minds - Simplifying CA Foundation Accounts Preparation",
          },
          {
            property: `og:description`,
            content: "Streamline and Simplify CA Foundation Accounts Prep with Notes, Shortcuts, Videos, Tests and Discussions",
          },
          {
            property: `og:image`,
            content: "/static/media/ca-foundation_accounts_youngminds.9c9fa9a1.png",
          },
          {
            property: `og:image:alt`,
            content: "YoungMinds CA Foundation Accounts",
          },
          {
            property: `og:image:width`,
            content: "500",
          },
          {
            property: `og:image:height`,
            content: "500",
          },
          {
            property: `og:url`,
            content:"https://youngminds.pro/ca-foundation-accounts",
          },
          {
            property: `og:type`,
            content: "website",
          },
        ]}
      />

      <AnimationRevealPage>
        <MainBanner
          roundedHeaderButton={true}
          mainTitle={
            <Title>
              <Ltext>CA Foundation Accounts</Ltext>
            </Title>
          }
          subTitle={<><Gtext>Accounts Sirf Pass hone ke Liye Nahin, Career Banane ke Liye Achhe se Seekhna Jaroori Hai. Isko Sahi Se Padhne ke 3 Faayde:</Gtext></>}
          buttonText="Let's Start"
          buttonUrl="https://wa.me/918826622806/?text=Hi, I would like to know more About Fit in Deutsch 1 Program"
          imageSrc={fitdeutsch}
          paragraph={<><p><b>Mazboot Financial Samajh:</b> Accounting ke principles ko gehrai se samajhkar financial duniya mein strong base banana.</p><p>&nbsp;</p><p><b>Career mein Tarakki ka Base:</b> Strong Accounts knowledge se CA Career mien aage badhne aur successful hone ke chances badh jaate hain.</p><p>&nbsp;</p><p><b>Confidence Badhaye:</b> Complex financial problems ko aasani se handle karne ki ability aati hai aur isse exams mein behtar performance hoti hai.</p></>}
          showClient={false}
          text="CA Foundation Accounts"
          knowmoreUrl=""
        />
        <TrackRecord
          textOnLeft={false}
          heading={
            <>
              The Unique{" "}
              <HighlightedText>Accounts Kunji and Self Assessment</HighlightedText>
            </>
          }
          description={
            <>
              <p>
                {" "}
                Every aspect of our content is meticulously curated to maximize understanding, enhance retention, minimize study time, and elevate your preparation each day until your CA Foundation Exam. With a <BoldTag>Structured 90-Day Study Plan</BoldTag>, we provide step-by-step guidance for your daily learning needs.
              </p>
              <br />
              <p>Hamara Self-Help Course Aapki Madad Ke Liye Focused Hai:</p>
              <p>
                <BoldTag>1. Concept Clarity Jaldi Se Paayein</BoldTag> - Tezi se concepts ko samajhne mein madad karta hai
                <br />
                <BoldTag>2. Aisi Wording Jo Aasani Se Samajh Aaye Aur Yaad Rahe</BoldTag> - Hinglish aur clear language ka istemal, jo aapko easily yaad rahe.
                <br />
                <BoldTag>3. Detailed Solutions Ke Saath Question Practice</BoldTag> - Har sawal ka thorough solution, taaki aapki practice behtar ho.
                <br />
                <BoldTag>4. Live Test Aur Discussion Sessions</BoldTag> - Regular live tests aur in-depth discussions se aap apne weak areas ko samajh kar unhe improve kar sakte hain.
              </p>
              
            </>
          }
          imageSrc={coursedetail}
          subheading="How YoungMinds is Different from Others ??"
           statistics = {[{
          key: "Notes",
          value: "Hinglish"
        },
        {
          key: "Questions",
          value: "Solved"
        },
        {
          key: "T & D",
          value: "Live"
        },
        {
          key: "with Benchmarking",
          value: "Self Practice"

        }]}
        primaryButtonText = "Accounts Study Plan "
        primaryButtonUrl = "WILL ADD"
        />
        <FeatureWithSteps
          subheading={<SubheadingSteps>{title}</SubheadingSteps>}
          heading={
            <>
              {heading} <HighlightedText>{highlightedtext}</HighlightedText>
            </>
          }
          textOnLeft={true}
          imageSrc={gols}
          steps={[
            {
              mainIcon: "",
              heading: "Are a Self Study Aspirant",
              description:
                "YoungMinds will be Your Best Friend in Your Prep Journey.",
            },

            {
              mainIcon: "",
              heading: "Want to be Exam Ready with Confidence",
              description:
                "AI Enabled Self Assessment, Solutions and Benchmarking & Live T&D for You",
            },

            {
              mainIcon: "",
              heading: "Want Help in Concept Learning",
              description:
                "Simplified Hinglish Notes on All Concepts for Easy Learning",
            },
          ]}
        />

        
      
        <FastestGrowing />
        <PreFooter fromPage = "CA Foundation Accounts" formheading = "Acounting is the Language of Business. Practice it Well." formsubheading = "Get More Details About the Program." heightlightText="Lets Start Learning"/>
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
