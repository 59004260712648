import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'];

const ThreeCharts = ({ ques_metrics }) => {
  // Data for each chart
  const accuracyData = [
    { name: 'Accuracy', value: ques_metrics?.avg_acc },
    { name: 'Remaining', value: 100 - ques_metrics?.avg_acc },
  ];

  const attemptsData = [
    { name: 'Correct', value: ques_metrics?.tot_ca },
    { name: 'Wrong', value: ques_metrics?.tot_wa },
    { name: 'Not Answered', value: ques_metrics?.tot_na },
  ];

  const calculatedData = [
    {
      name: 'Attempts per Student',
      value: ques_metrics?.tot_attempts / ques_metrics?.tot_students,
    },
    {
      name: 'Remaining Capacity',
      value:
        ques_metrics?.tot_students -
        ques_metrics?.tot_attempts / ques_metrics?.tot_students,
    },
  ];

  // Custom Legend Component
  const CustomLegend = ({ data }) => (
    <ul style={{ listStyle: 'none', padding: 0, textAlign: 'left' }}>
      {data.map((entry, index) => (
        <li
          key={`legend-${index}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '5px',
          }}
        >
          <span
            style={{
              display: 'inline-block',
              width: '16px',
              height: '16px',
              backgroundColor: COLORS[index % COLORS.length],
              marginRight: '8px',
              borderRadius: '2px',
            }}
          ></span>
          {entry.name}: {(entry.value).toFixed(2)}
        </li>
      ))}
    </ul>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        marginTop: '50px',
      }}
    >
      {/* Chart 1: Accuracy */}
      <div style={{ width: '100%', maxWidth: '300px', margin: '10px', textAlign: 'center' }}>
        <h3>Accuracy</h3>
        <PieChart width={200} height={200}>
          <Pie
            data={accuracyData}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {accuracyData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
        <CustomLegend data={accuracyData} />
      </div>

      {/* Chart 2: Attempts (Correct, Wrong, Not Answered) */}
      <div style={{ width: '100%', maxWidth: '300px', margin: '10px', textAlign: 'center' }}>
        <h3>Attempts Distribution</h3>
        <PieChart width={200} height={200}>
          <Pie
            data={attemptsData}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {attemptsData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
        <CustomLegend data={attemptsData} />
      </div>

      {/* Chart 3: Calculated Data */}
      <div style={{ width: '100%', maxWidth: '300px', margin: '10px', textAlign: 'center' }}>
        <h3>Calculated Data</h3>
        <PieChart width={200} height={200}>
          <Pie
            data={calculatedData}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {calculatedData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
        <CustomLegend data={calculatedData} />
      </div>
    </div>
  );
};

export default ThreeCharts;
